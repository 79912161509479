import React from "react"
import { Row } from "react-bootstrap"
import { forteData } from "../common/dataJSON"

export default function ForteMobile() {
  return (
    <>
      {forteData.map(({ name, img, content, id }) => (
        <Row className="p-2" key={id}>
          <div className="d-flex justify-content-center">
            <h3>{name}</h3>
          </div>
          <div
            className="img-row mt-2 p-4"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={img} width="90%" height="100%" alt="" />
          </div>
          <div className="mt-4">
            <p style={{ marginLeft: "10px", textAlign: "justify" }} dangerouslySetInnerHTML={{ __html: content }}>
            </p>
          </div>
        </Row>
      ))}
    </>
  )
}
