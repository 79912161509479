// import React, { useState, useEffect } from "react";
// import { Col, Row, Card, Container } from "react-bootstrap";
// import Slider from "react-slick";
// import {
//   partnersData,
//   industrialPartnerData,
//   skillEnablersData,
//   strategicPartnersData,
//   proudMembersData,
//   combinedData,
// } from "../common/dataJSON";

// export default function OtherPartners() {
//   const settings = {
//     className: "center",
//     // centerMode: true,
//     dots: true,
//     infinite: true,
//     autoplay: false,
//     speed: 700,
//     slidesToShow: 4,
//     slidesToScroll: 4,
//     rows: 2,
//     // slidesPerRow: 4,
//     arrows: true,
//     responsive: [
//       {
//         breakpoint: 1000,
//         settings: {
//           rows: 2,
//           speed: 500,
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           dots: true,
//           arrows: true,
//           infinite: true,
//           autoplay: true,
//         },
//       },
//       {
//         breakpoint: 800,
//         settings: {
//           rows: 1,
//           speed: 500,
//           slidesToShow: 3,
//           slidesToScroll: 3,
//           dots: false,
//           arrows: true,
//           infinite: true,
//           autoplay: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           rows: 1,
//           speed: 500,
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           dots: false,
//           // arrows: true,
//           infinite: true,
//           autoplay: true,
//         },
//       },
//       {
//         breakpoint: 400,
//         settings: {
//           rows: 1,
//           speed: 500,
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           dots: false,
//           // arrows: true,
//           infinite: true,
//           autoplay: true,
//         },
//       },
//     ],
//   };

//   const industrialSettings = {
//     dots: false,
//     infinite: true,
//     autoplay: true,
//     speed: 600,
//     slidesToShow: 4,
//     slidesToScroll: 2,
//     responsive: [
//       { breakpoint: 1000, settings: { slidesToShow: 3, slidesToScroll: 2 } },
//       { breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 1 } },
//       { breakpoint: 400, settings: { slidesToShow: 1, slidesToScroll: 1 } },
//     ],
//   };

//   const strategicPartnersSettings = {
//     dots: true,
//     infinite: true,
//     autoplay: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     fade: true,
//     responsive: [
//       {
//         breakpoint: 800,
//         settings: {
//           fade: false,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           fade: false,
//         },
//       },
//     ],
//   };

//   const skillEnablersSettings = {
//     dots: false,
//     infinite: true,
//     autoplay: true,
//     speed: 2000,
//     slidesToShow: 4,
//     slidesToScroll: 2,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1000,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 400,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const proudMembersSettings = {
//     dots: false,
//     infinite: true,
//     autoplay: true,
//     speed: 2000,
//     slidesToShow: 4,
//     slidesToScroll: 2,
//     arrows: false,
//     rtl: true,
//     responsive: [
//       {
//         breakpoint: 1000,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 400,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   // Merged partner data arrays
//   // const combinedData = [
//   //   ...partnersData.map((item) => ({ type: "General", src: item })),
//   //   ...industrialPartnerData.map((item) => ({ type: "Industrial", src: item })),
//   //   ...strategicPartnersData.map((item) => ({ type: "Strategic", src: item })),
//   // ];

//   // To track whether the header for each section has already been shown
//   const [shownHeaders, setShownHeaders] = useState({
//     General: false,
//     Industrial: false,
//     Strategic: false,
//   });

//   const groupedData = {
//     General: partnersData,
//     Industrial: industrialPartnerData,
//     Strategic: strategicPartnersData,
//   };

//   return (
//     <>
//       {/* <Container fluid>
//         {combinedData.map((category, index) => (
//           <div key={index} className="my-5">
//             <h2 className="text-center mb-4">{category.title}</h2>
//             <Slider {...sliderSettings}>
//               {category.images.map((image, i) => (
//                 <div key={i}>
//                   <Card className="text-center border-0">
//                     <Card.Img
//                       variant="top"
//                       src={image}
//                       alt={`${category.title} ${i + 1}`}
//                     />
//                   </Card>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         ))}
//       </Container> */}

//       <Row>
//         <div className="">
//           <h3 className="section-title">Skill Enablers</h3>
//           <Slider {...skillEnablersSettings}>
//             {skillEnablersData.map((item, index) => (
//               <div key={index} className="client-row">
//                 <Card className="client-content">
//                   <Card.Img src={item} className="partners-img-slider" />
//                 </Card>
//               </div>
//             ))}
//           </Slider>
//         </div>

//         <div className="proud-members-container">
//           <h3 className="section-title">Proud Members</h3>
//           <Slider {...proudMembersSettings}>
//             {proudMembersData.map((item, index) => (
//               <div key={index} className="client-row">
//                 <Card className="client-content">
//                   <Card.Img src={item} className="partners-img-slider" />
//                 </Card>
//               </div>
//             ))}
//           </Slider>
//         </div>
//       </Row>
//     </>
//   );
// }
import React from "react";
import { Container } from "react-bootstrap";
import Marquee from "react-fast-marquee";
import { skillEnablersData, proudMembersData } from "../common/dataJSON";

export default function OtherPartners() {
  return (
    <Container fluid className="other-partners-container">
      {/* Skill Enablers Section */}
      <div className="pt-10" style={{paddingTop:15}}>
        <h3 className="section-title text-center mb-0 sm:mb-4"
        style={{backgroundColor:'#e62431',padding:5, color:'white'}}
        >Skill Enablers</h3>
        <div className="marquee-wrapper">
          <Marquee
            gradient={false}
            speed={50}
            pauseOnHover={true}
          >
            {skillEnablersData.map((item, index) => (
              <div key={index} className="marquee-item">
                <div className="marquee-card">
                  <img src={item} alt={`Skill Enabler ${index}`} className="partners-img-marquee" />
                </div>
              </div>
            ))}
             {skillEnablersData.map((item, index) => (
              <div key={index} className="marquee-item">
                <div className="marquee-card">
                  <img src={item} alt={`Skill Enabler ${index}`} className="partners-img-marquee" />
                </div>
              </div>
            ))}
          </Marquee>
        </div>
      </div>

      {/* Proud Members Section */}
      {/* <div className="my-5">
        <h3 className="section-title text-center mb-4">Proud Members</h3>
        <div className="marquee-wrapper">
          <Marquee
            gradient={false}
            speed={50}
            direction="right"
            pauseOnHover={true}
          >
            {proudMembersData.map((item, index) => (
              <div key={index} className="marquee-item">
                <div className="marquee-card">
                  <img src={item} alt={`Proud Member ${index}`} className="partners-img-marquee" />
                </div>
              </div>
            ))}
          </Marquee>
        </div>
      </div> */}
    </Container>
  );
}
