import React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Emtypath from "../components/Emtypath"
import SolutionDetails from "../components/solutionDetails"
import Webreathe from "../components/webreathe"
import Home from "../pages"
import {
  SOLUTION_ROUTE,
  WEBREATHE_ROUTE,
  CONTACT_US,
  FORTE_DETAILS_ROUTE,
  OUR_TEAM,
} from "./routePath"
import ContactUs from "../components/contactUs"
import ForteDetails from "../components/forteDetails"
import OurTeam from '../components/OurTeam'

function RoutePath() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={`${SOLUTION_ROUTE}/:id`} element={<SolutionDetails />} />
          <Route
            path={`${FORTE_DETAILS_ROUTE}/:id`}
            element={<ForteDetails />}
          />
          <Route path={WEBREATHE_ROUTE} element={<Webreathe />} />
          <Route path="*" element={<Emtypath />} />
          <Route path={CONTACT_US} element={<ContactUs />} />
          <Route path={OUR_TEAM} element={<OurTeam />} />
        </Routes>
      </Router>
    </div>
  )
}

export default RoutePath
