import React, { useState, useEffect } from "react";
import { Col, Row, Card, Container } from "react-bootstrap";
import Slider from "react-slick";
import {
  partnersData,
  industrialPartnerData,
  skillEnablersData,
  strategicPartnersData,
  proudMembersData,
  combinedData,
} from "../common/dataJSON";

export default function Partners() {
  const settings = {
    className: "center",
    // centerMode: true,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    rows: 2,
    arrows: false,
    // slidesPerRow: 4,

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          rows: 2,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          arrows: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          rows: 1,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
          arrows: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          rows: 1,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
          // arrows: true,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          rows: 1,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          // arrows: true,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2300,
    arrows: false,
    pauseOnHover: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 640, settings: { slidesToShow: 1 } },
    ],
  };


  // Slider settings
  const strategicPartnersSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 1700,
    arrows: false,
    pauseOnHover: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 640, settings: { slidesToShow: 1 } },
    ],
  };
  
  const proudMembersSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    rtl:true,
    pauseOnHover: true,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 640, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <>
      <Row>
        <Col>
          <h3 className="title-text">Global Connections</h3>
        </Col>
      </Row>

      <div className="">
        <Container>
          <h3 className="section-title">Industrial Partners</h3>

          <Slider {...sliderSettings}>
            {partnersData.map(({ name, logo }, index) => (
              <div key={index} className="px-3">
                {/* Card Container */}
                <div className="spectacledcoder-card2">
                  {/* Gradient Effect Layer */}
                  <div className="circle2"></div>
                  {/* Card Content */}
                  <div className="content">
                    <Row className="client-row">
                      <Card className="client-content">
                        <Card.Img src={logo} className="partners-img" />
                      </Card>
                    </Row>
                    <h5 className="text-lg text-center font-medium">{name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div>

      {/* Strategic Partners Slider */}
      <div className="" style={{ marginTop: 40 }}>
        <Container>
          <h3 className="section-title">Strategic Partners</h3>

          <Slider {...strategicPartnersSettings}>
            {strategicPartnersData.map(({ name, logo }, index) => (
              <div key={index} className="px-3">
                {/* Card Container */}
                <div className="stragetic-partner-card">
                  {/* Gradient Effect Layer */}
                  <div className="circle2"></div>
                  {/* Card Content */}
                  <div className="content">
                    <Row className="client-row">
                      <Card className="client-content">
                        <Card.Img src={logo} className="partners-img" />
                      </Card>
                    </Row>
                    <h5 className="text-lg text-center font-medium">{name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div>

      <div className="" style={{ marginTop: 40 }}>
        <Container>
          <h3 className="section-title">Proud Members</h3>

          <Slider {...proudMembersSettings}>
            {proudMembersData.map(({ name, logo }, index) => (
              <div key={index} className="px-3">
                {/* Card Container */}
                <div className="proud-member-card">
                  {/* Gradient Effect Layer */}
                  <div className="gradient-circle"></div>
                  {/* Card Content */}
                  <div className="content">
                    <Row className="client-row">
                      <Card className="client-content">
                        <Card.Img src={logo} className="partners-img" />
                      </Card>
                    </Row>
                    <h5 className="text-lg text-center font-medium">{name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div>

    </>
  );
}

{
  /* <div className="py-10">
        <Container>
          <h3 className="section-title">Industrial Partners</h3>

          <Slider {...sliderSettings}>
            {partnersData.map(({ name, logo }, index) => (
              <div key={index} className="px-3 ">
                <div
                  className="shadow-md bg-white overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg"
                  style={{ borderRadius: "10px" }}
                >
                  <div className="flex items-center justify-center" style={{}}>
                    <Row className="client-row bg-white" key={index}>
                      <Card className="client-content bg-white ">
                        <Card.Img
                          src={logo}
                          className="partners-img bg-white"
                        />
                      </Card>
                    </Row>
                    <h5 className="text-lg text-center font-medium text-gray-700">
                      {name}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div> */
}

{
  /* <Slider {...settings}>
        {partnersData.map((image, index) => (
          <Row className="client-row" key={index}>
            <Card className="client-content">
              <Card.Img src={image} className="partners-img" />
            </Card>
          </Row>
        ))}
      </Slider> */
}

{
  /* <div className="" style={{ marginTop: 40 }}>
        <Container>
          <h3 className="section-title">Strategic Partners</h3>

          <Slider {...sliderSettings}>
            {strategicPartnersData.map(({ name, logo }, index) => (
              <div key={index} className="px-3">
                <div
                  className="shadow-md bg-white overflow-hidden transition-transform transform hover:scale-105 hover:shadow-lg"
                  style={{ borderRadius: "10px" }}
                >
                  <div
                    className="flex items-center justify-center"
                    style={{ borderRadius: "30px" }}
                  >
                    <Row className="client-row bg-white" key={index}>
                      <Card className="client-content bg-white ">
                        <Card.Img
                          src={logo}
                          className="partners-img bg-white"
                        />
                      </Card>
                    </Row>
                    <h5 className="text-lg text-center font-medium text-gray-700">
                      {name}
                    </h5>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div> */
}
