import React from "react"
import { Button, Form, Card } from "react-bootstrap"
import { useFormik } from "formik"
import emailjs from "@emailjs/browser"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

function Enquiry() {
  const formik = useFormik({
    initialValues: {
      service: "",
      message: "",
      Name: "",
      email: "",
      phoneNumber: "",
    },
    onSubmit: (values) => {
      console.log(values)
      if (values) {
        const serviceId = "service_o8ay9x6"
        const templateId = "template_k04sf6q"
        const YOUR_PUBLIC_KEY = "1jxERoRp8gRTEWl8A"
        const templateParams = values

        emailjs
          .send(serviceId, templateId, templateParams, YOUR_PUBLIC_KEY)
          .then(
            (response) => {
              console.log("SUCCESS!", response.status, response.text)
              if (response.status === 200) {
                toast.success("Successfull")
              } else {
                toast.error("Failed")
              }
              formik.resetForm()
            },
            (err) => {
              console.log("FAILED...", err)
            }
          )
      }
    },
  })
  return (
    <>
      <ToastContainer />
      <Card id="contact">
        <Form
          className="d-flex justify-content-center flex-column"
          onSubmit={formik.handleSubmit}
        >
          <Form.Group className="mb-1 w-[100%]">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="Name"
              onChange={formik.handleChange}
              value={formik.values.Name}
              required
            />
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              name="email"
              required
              value={formik.values.email}
              onChange={formik.handleChange}
              type="email"
            />
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              required
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              type="tel"
            />
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>service</Form.Label>
            <Form.Control
              name="service"
              required
              value={formik.values.service}
              onChange={formik.handleChange}
              type="text"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Message</Form.Label>
            <Form.Control
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              type="text"
              required
            />
          </Form.Group>
          <Form.Group className=" d-flex justify-content-center">
            <Button variant="primary" type="submit">
              SEND ENQUIRY
            </Button>
          </Form.Group>
        </Form>
      </Card>
    </>
  )
}

export default Enquiry
