// import React, { useState } from "react";
// import Navbar from "react-bootstrap/Navbar";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import CompanyLogo from "../../assets/logo.svg";
// import { OUR_TEAM, WEBREATHE_ROUTE } from "../../routes/routePath";
// import { useMediaQuery } from "react-responsive";

// export default function Header({ HideContent }) {
//   const [showNav, setShowNav] = useState(false);
//   const [activeNav, setActiveNav] = useState("home");
//   const isMobile = useMediaQuery({ query: "(max-width: 768px)" }); // Ensure query is properly formatted
//   const location = useLocation();
//   const navigate = useNavigate();

//   const goToElemment = (element) => {
//     // HideContent(null)
//     setActiveNav(element);
//     let offset = 100;
//     if (document.getElementById(element)) {
//       window.scrollTo({
//         behavior: "smooth",
//         top:
//           document.getElementById(element).getBoundingClientRect().top -
//           document.body.getBoundingClientRect().top -
//           offset,
//       });
//     }
//     setShowNav(!showNav);
//   };

//   const goToSolution = () => {
//     navigate(`${WEBREATHE_ROUTE}`);
//   };
//   const goToOurTeam = () => {
//     navigate(`${OUR_TEAM}`);
//   };

//   return (
//     <Navbar className="header-content">
//       <div className="container">
//         <Link to="/">
//           <img alt="Atribs" src={CompanyLogo} />
//         </Link>
//         <div>
//           {showNav && (
//             <ul className="header-section">
//               <li
//                 onClick={() => {
//                   location.pathname === "/webreathe"
//                     ? navigate("/")
//                     : goToElemment("home");
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "home" && "active"}`}
//               >
//                 <a>Home</a>
//               </li>
//               <li
//                 onClick={() => {
//                   location.pathname === "/webreathe"
//                     ? navigate("/")
//                     : goToElemment("forte");
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "forte" && "active"}`}
//               >
//                 <a>Our Forte</a>
//               </li>
//               <li
//                 onClick={() => {
//                   location.pathname === "/webreathe"
//                     ? navigate("/")
//                     : goToElemment("solution");
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "solution" && "active"}`}
//               >
//                 <a>Technology</a>
//               </li>
//               {/* <li
//                 onClick={() => goToElemment("partial")}
//                 className={`${activeNav === "partial" && "active"}`}
//               >
//                 <a>Clients</a>
//               </li> */}
//               <li
//                 onClick={() => {
//                   location.pathname === "/webreathe"
//                     ? navigate("/")
//                     : goToElemment("partners");
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "partners" && "active"}`}
//               >
//                 <a>Partners</a>
//               </li>
//               <li
//                 onClick={() => {
//                   location.pathname === "/webreathe"
//                     ? navigate("/")
//                     : goToSolution();
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "about" && "active"}`}
//               >
//                 <a>
//                   <Link to={"/webreathe"} onClick={() => setShowNav(true)}>
//                     We Breathe
//                   </Link>
//                 </a>
//               </li>
//               <li
//                 onClick={() => {
//                   goToElemment("about");
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "about" && "active"}`}
//               >
//                 <a>Who we are</a>
//               </li>
//               <li
//                 onClick={() => {
//                   location.pathname === "/webreathe"
//                     ? navigate("/")
//                     : goToElemment("career");
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "career" && "active"}`}
//               >
//                 <a>Careers</a>
//               </li>
//               <li
//                 onClick={() => {
//                   location.pathname === "/webreathe"
//                     ? navigate("/")
//                     : goToElemment("contact");
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "contact" && "active"}`}
//               >
//                 <a>Reach Us</a>
//               </li>
//               <li
//                 onClick={() => {
//                   location.pathname === "/webreathe"
//                     ? navigate("/")
//                     : goToOurTeam();
//                   setShowNav(true);
//                 }}
//                 className={`${activeNav === "ourTeam" && "active"}`}
//               >
//                 <a>
//                   <Link to={"/ourTeam"} onClick={() => setShowNav(true)}>
//                     Our Team
//                   </Link>
//                 </a>
//               </li>
//             </ul>
//           )}
//         </div>

//           <div
//             className={showNav ? "hambarg-btn" : "hambarg"}
//             onClick={() => setShowNav(!showNav)}
//           >
//             <i className={`bi bi-${!showNav ? "text-left" : "x"}`}></i>
//           </div>

//       </div>
//     </Navbar>
//   );
// }

import React, { useState,useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CompanyLogo from "../../assets/logo.svg";
import { OUR_TEAM, WEBREATHE_ROUTE } from "../../routes/routePath";
import { useMediaQuery } from "react-responsive";

export default function Header({ HideContent }) {
  const [showNav, setShowNav] = useState(false);
  const [activeNav, setActiveNav] = useState("home");
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1024px)" });
  const location = useLocation();
  const navigate = useNavigate();
// Debugging
console.log("isTablet:", isTablet); // Should be true for devices <= 1024px
console.log("isMobile:", isMobile); // Should be true for devices <= 768px


  const goToElemment = (element) => {
    setActiveNav(element);
    let offset = 100;
    if (document.getElementById(element)) {
      window.scrollTo({
        behavior: "smooth",
        top:
          document.getElementById(element).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset,
      });
    }
    setShowNav(false); // Close the menu when an item is clicked
  };

  const goToSolution = () => {
    navigate(`${WEBREATHE_ROUTE}`);
    setShowNav(false);
  };
  const goToOurTeam = () => {
    navigate(`${OUR_TEAM}`);
    setShowNav(false);
  };

  return (
    <Navbar className="header-content">
      <div className="container">
        <Link to="/">
          <img alt="Atribs" src={CompanyLogo} />
        </Link>

        {/* Menu items for larger screens */}
        <ul className={`header-section ${showNav ? "open" : ""}`}>
          <li
            onClick={() => goToElemment("home")}
            className={`${activeNav === "home" && "active"}`}
          >
            <a>Home</a>
          </li>
          <li
            onClick={() => goToElemment("forte")}
            className={`${activeNav === "forte" && "active"}`}
          >
            <a>Our Forte</a>
          </li>
          <li
            onClick={() => goToElemment("solution")}
            className={`${activeNav === "solution" && "active"}`}
          >
            <a>Technology</a>
          </li>
          <li
          // onClick={() => goToElemment("solution")}
          // className={`${activeNav === "solution" && "active"}`}
          >
            <a
              href="https://www.shieldskillhub.com/" // Replace with your desired URL
              target="_blank" // Opens the link in a new tab (optional)
              rel="noopener noreferrer" // For security and performance
              className="custom-link"
            >
              L & D
            </a>{" "}
          </li>
          <li
            onClick={() => goToElemment("partners")}
            className={`${activeNav === "partners" && "active"}`}
          >
            <a>Partners</a>
          </li>
          <li
            onClick={goToSolution}
            className={`${activeNav === "about" && "active"}`}
          >
            <a>
              <Link to="/webreathe">We Breathe</Link>
            </a>
          </li>
          <li
            onClick={() => goToElemment("about")}
            className={`${activeNav === "about" && "active"}`}
          >
            <a>Who we are</a>
          </li>
          <li
            onClick={() => goToElemment("career")}
            className={`${activeNav === "career" && "active"}`}
          >
            <a>Careers</a>
          </li>
          <li
            onClick={() => goToElemment("contact")}
            className={`${activeNav === "contact" && "active"}`}
          >
            <a>Reach Us</a>
          </li>
          <li
            onClick={goToOurTeam}
            className={`${activeNav === "ourTeam" && "active"}`}
          >
            <a>
              <Link to="/ourTeam">Our Team</Link>
            </a>
          </li>
        </ul>

        {/* Hamburger icon for mobile */}
        {isTablet && (
          <div
            className={`hambarg ${showNav ? "hambarg-btn" : ""}`}
            onClick={() => setShowNav(!showNav)}
          >
            <i className={`bi bi-${!showNav ? "text-left" : "x"}`}></i>
          </div>
        )}
      </div>
    </Navbar>
  );
}
