import React, { useState } from "react";
import { Col, Row, Card } from "react-bootstrap";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { aboutDetails } from "../common/dataJSON";

export default function About({ setAbout }) {
  const [activeTab, setActiveTab] = useState(0);
  const [activeBtn, setActiveBtn] = useState("next");

  const handleAbout = (tab) => {
    switch (tab) {
      case "ABOUT US":
        setActiveTab(0);
        break;
      case "OUR MISSION":
        setActiveTab(1);
        break;
      case "OUR TEAM":
        setActiveTab(2);
        break;
      case "LEARN-DEVELOP":
        setActiveTab(3);
        break;
      default:
        setActiveTab(0);
        break;
    }
  };

  const handleTab = (tab) => {
    if (tab === "prev") {
      setActiveBtn("prev");
      if (activeTab !== 0) {
        setActiveTab(activeTab - 1);
      }
    } else {
      setActiveBtn("next");
      if (activeTab !== aboutDetails.length - 1) {
        setActiveTab(activeTab + 1);
      }
    }
  };

  return (
    <>
      <Row>
        <h1 className="title">
          who we are:
          <span> "powered by people"</span>
        </h1>
      </Row>
      <Row className="aboutTab">
        {aboutDetails.map(({ name, id }) => (
          <Col
            className={`bdy-content ${activeTab == id - 1 && "active-title"}`}
            onClick={() => handleAbout(name)}
          >
            {activeTab == id - 1 && (
              <div className="active-border">
                <div className="red-b"></div>
                <div className="black-b"></div>
              </div>
            )}
            <span>{name}</span>
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="about-col">
          <Card.Img src={aboutDetails[activeTab].img} className="img-col" />
          <Card.Text className="text-col">
            {/* {aboutDetails[activeTab].content} */}
            {/* dangerouslySetInnerHTML={{ __html: aboutDetails[activeTab].content }} */}
            <div
      dangerouslySetInnerHTML={{
        __html: aboutDetails[activeTab].content
      }}
    />
            <Row>
              <Col>
                <HiOutlineArrowLeft
                  className={`icon-col ${activeTab !== 0 && "active"}`}
                  disabled={activeTab == 0}
                  onClick={() => handleTab("prev")}
                />
                <HiOutlineArrowRight
                  className={`icon-col ${
                    activeTab !== aboutDetails.length - 1 && "active"
                  }`}
                  disabled={activeTab == aboutDetails.length - 1}
                  onClick={() => handleTab("next")}
                />
              </Col>
              <Col
                className={`${
                  ["ABOUT US", "LEARN-DEVELOP"].includes(aboutDetails[activeTab].name)
                    ? "btn"
                    : "d-none"
                }`}
              >
                {aboutDetails[activeTab].name === "ABOUT US" ? (
                  <button onClick={() => setAbout(true)} className="inline-btn">
                    {" "}
                    LEARN MORE{" "}
                  </button>
                ) : aboutDetails[activeTab].name === "LEARN-DEVELOP" ? (
                  <button
                    onClick={() =>
                      window.open("https://www.shieldskillhub.com", "_blank")
                    }
                    className="inline-btn"
                  >
                    LEARN MORE
                  </button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Card.Text>
        </Col>
      </Row>
    </>
  );
}
